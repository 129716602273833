import React from 'react';
import * as d3 from 'd3';
import './Chart.css';


class RowChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      yAxisAttribute: "skill",
      xAxisAttribute: "value"
    }

  }

  componentDidMount() {
  }

  render() {

    const { data, width, height, margin } = this.props
    const h = height - (2 * margin);
    const w = width - (2 * margin);

    //number formatter
    var dollarFormat = (d) => { return d3.format('^$.0s')(d) };
    var monthFormat = (d) => { return d3.format('')(d) };

    //x scale
    const xScale = d3.scaleLinear()
      .domain(d3.extent(data, d => d.a)) //domain: [min,max] of a
      .range([margin, w])

    //y scale
    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.b)]) // domain [0,max] of b (start from 0)
      .range([h, margin])

    //line generator: each point is [x(d.a), y(d.b)] where d is a row in data
    // and x, y are scales (e.g. x(10) returns pixel value of 10 scaled by x)
    const line = d3.line()
      .x(d => xScale(d.a))
      .y(d => yScale(d.b))
      .curve(d3.curveMonotoneX) //curve line


    const xTicks = xScale.ticks(5).map(d => (
      xScale(d) > margin && xScale(d) < width ?
        <g transform={`translate(${xScale(d)},${h})`}>
          <text transform="translate(0,0)">{monthFormat(d)}</text>
          <line x1='0' x1='0' y1='0' y2='5' transform="translate(0,0)" />
        </g>
        : null
    ))

    const yTicks = yScale.ticks(4).map(d => (
      yScale(d) > 10 && yScale(d) < h ?
        <g transform={`translate(${margin},${yScale(d)})`}>
          <text x={-margin}>{dollarFormat(d)}</text>
          <line x1='0' x1='5' y1='0' y2='0' transform="translate(-5,0)" />
        </g>
        : null
    ))

    return (
      <svg style={{ width: "100%", height: height }}>
        <g className="axis text">
          {xTicks}
        </g>
        <g className="axis">
          {yTicks}
        </g>
        <g>
          {d3.axisBottom(xScale)}
          {d3.axisLeft(yScale)}
        </g>
        <path className="axis line" d={line(data)} />
      </svg>
    )
  }
}

export default RowChart;