import React from 'react';
import './Interest.css';
class Interest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
    }
    this.handleChange = this.handleChange.bind(this);
  }


  componentWillUnmount() {
  }

  handleChange(event) {
    if (event.target.name === 'year') {
      this.props.onChange(this.props.id, this.props.interest, event.target.value)
    }
    else {
      this.props.onChange(this.props.id, event.target.value / 100, this.props.year)
    }
  }

  render() {

    return (
      <div className="interestOption">
        <table>
          <tr>
            <td>
              <form className='.form-container'>
                <input
                  className="input100"
                  type="number"
                  name="year"
                  placeholder="Year"
                  defaultValue={this.props.year}
                  readOnly={this.props.yearReadOnly}
                  onChange={this.handleChange}></input>
                <input
                  className="input100"
                  type="number"
                  name="interest"
                  placeholder="%"
                  defaultValue={this.props.interest * 100}
                  onChange={this.handleChange}
                >
                </input>
              </form>
            </td>
            <td>
              <div className="outer">
                <div className="inner">
                  <label id={this.state.key} onClick={() => this.props.handleDeleteClick(this.state.id)}>DELETE</label>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default Interest;