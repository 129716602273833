//TODO: figure out how to handle interest rate changes outside the planned repayment time

class InterestRateCalculator {
  constructor(investment, repayments, payementsPerYear, interestRates) {
    this.years = 30;
    this.interestRate = interestRates[0].interestRate;
    this.investment = investment;
    this.initialInvestment = investment
    this.paymentsPerYear = payementsPerYear;
    this.i = (this.interestRate) / this.paymentsPerYear;
    this.n = (this.years * this.paymentsPerYear);

    this.interestRates = interestRates;
    this.interestRateArray = this.generateInterestRateArray();
    this.repayments = repayments//calculateRepayments(this.i, this.n, this.investment)
    this.error = false;
  }

  calculateRepayments(i, n, investment) {
    var repayments = -investment * ((i * Math.pow((1.00 + i), n)) / (1.00 - Math.pow((1 + i), n)))
    return repayments.toFixed(2);
  }

  setRepaymentsToDefault() {
    this.repayments = this.calculateRepayments(this.i, this.n, this.initialInvestment);
  }

  getRepayments() {
    return this.repayments;
  }

  setRepayments(repayments) {
    this.repayments = repayments;
  }

  generateInterestRateArray() {
    var _interestRateArray = [];
    this.interestRates.forEach((element, index) => {
      var interest = element.interestRate;
      var startYear = element.startYear;
      var nextPeriod = this.interestRates[index + 1]
      var endYear = nextPeriod ? nextPeriod.startYear : this.years;
      for (var j = startYear; j < endYear; j++) {
        _interestRateArray.push(interest);
      }
    });
    console.log()
    return _interestRateArray;
  }

  isNewYear(paymentPeriod) {
    if (paymentPeriod % this.paymentsPerYear === 1 && paymentPeriod !== 1) {
      return true;
    }
    return false;
  }


  getInterestRateIndex(year) {
    var interestRateIndex = year;
    if (year >= this.interestRateArray.length) {
      interestRateIndex = this.interestRateArray.length - 1;
    }
    return interestRateIndex;
  }

  interestPerPayment(interestRateIndex) {
    return (this.interestRateArray[interestRateIndex]) / this.paymentsPerYear
  }


  //Returns an array of the monthly repayments
  getData() {
    var data = [{ a: 0, b: this.investment }]
    var year = 0;
    var interestRateIndex;
    var currentPaymentPeriod = 1;
    var interestPercentPerPayment;

    //keep calculating until we've repayed within reason
    while (Math.round(this.investment) > 5) {
      interestRateIndex = this.getInterestRateIndex(year);
      //just make sure that we don't get an out of bounds
      year = this.isNewYear(currentPaymentPeriod) ? year + 1 : year;

      var newPoint = {};
      interestPercentPerPayment = this.interestPerPayment(interestRateIndex);
      var interestDue = interestPercentPerPayment * this.investment;
      this.investment = this.investment - (this.repayments - interestDue)

      if (this.isNewYear(currentPaymentPeriod)) {
        newPoint.a = year;
        newPoint.b = this.investment;
        data.push(newPoint);
      }
      currentPaymentPeriod++;

      if (currentPaymentPeriod > 100 * this.paymentsPerYear) {
        this.error = true;
        return data;
      }
    }
    this.error = false;
    return data;
  }
}

export default InterestRateCalculator;





