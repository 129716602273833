import React from 'react';
import './App.css';
import Interest from './Interest.js'
import RowChart from './Chart.js'
import '../node_modules/react-vis/dist/style.css';
import InterestRateCalculator from './interestCalculator.js';
// import { XYPlot, LineSeries } from 'react-vis';
// npm install react-vis

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interestRatesCreatedCounter: 0,
      investment: 685000,
      repayments: 700,
      PaymentFrequency: 52,
      interestRates: [{
        key: 0,
        startYear: 0,
        interestRate: 0.03
      }],
      graphWidth: 800,
      graphHeight: 350,
      graphMargin: 30,
    };
    this.interestDetailChangeHandler = this.interestDetailChangeHandler.bind(this);
    this.handleInvestmentChange = this.handleInvestmentChange.bind(this);
    this.handleRepaymentChange = this.handleRepaymentChange.bind(this);
    this.handleInterestDeleteClick = this.handleInterestDeleteClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    var width = window.innerWidth < 500 ? (window.innerWidth * 0.95) : (window.innerWidth / 2);
    this.setState({ graphWidth: width, graphHeight: window.innerHeight / 2.5 });
  }

  interestDetailChangeHandler(key, interest, year) {
    var tempinterestRates = this.state.interestRates;
    var index = this.getInterestRateIndexWithID(key);
    tempinterestRates[index].interestRate = interest;
    tempinterestRates[index].startYear = year;
    this.setState({
      interestRates: tempinterestRates
    });

  }

  generateInterestRateChildren() {
    var interestRateElements = [];
    this.state.interestRates.forEach((element) => {
      interestRateElements.push(
        <Interest key={element.key}
          id={element.key}
          year={element.startYear}
          interest={element.interestRate}
          yearReadOnly={element.key === 0 ? true : false}
          onChange={this.interestDetailChangeHandler}
          handleDeleteClick={this.handleInterestDeleteClick}>
        </Interest>)
    });
    return interestRateElements;
  }

  handleInterestPeriodAddClick() {
    var interestRates = this.state.interestRates;
    var lastYearInterestSet = interestRates[interestRates.length - 1].startYear;
    interestRates.push({
      key: this.state.interestRatesCreatedCounter + 1,
      startYear: Number(lastYearInterestSet) + 1,
      interestRate: this.state.interestRates[this.state.interestRates.length - 1].interestRate
    });
    this.setState({
      interestRates: interestRates,
      interestRatesCreatedCounter: this.state.interestRatesCreatedCounter + 1,
    })
  }

  handleInvestmentChange(event) {
    var newState = this.state;
    newState.investment = event.target.value;
    this.setState(newState);
  }

  handleRepaymentChange(event) {
    const repayment = event.target.value
    this.setState({
      repayments: repayment
    })
  }

  handleCalculateRepaymentClick(calc) {
    var newState = this.state;
    calc.setRepaymentsToDefault();
    newState.repayments = calc.getRepayments();
    this.setState(newState);
  }

  //TODO: Figure out why if you update the keys it behaves very odd
  handleInterestDeleteClick(key) {
    if (key === 0) {
      return;
    }
    var _interestRates = this.state.interestRates;
    var index = this.getInterestRateIndexWithID(key);
    _interestRates.splice(index, 1);
    var newState = this.state;
    newState.interestRates = _interestRates
    this.setState(newState);
  }

  getInterestRateIndexWithID(id) {
    for (var i = 0; i < this.state.interestRates.length; i++) {
      var index = i;
      if (this.state.interestRates[i].key === id) {
        return index;
      }
    }
    return null;
  }

  handlePaymentFrequencyChange(event) {

  }

  render() {
    var calc = new InterestRateCalculator(this.state.investment, this.state.repayments, this.state.PaymentFrequency, this.state.interestRates);
    return (
      <div className="App" >
        <header className="App-header">
          <p>
            Calculate loan Repayments
          </p>
        </header>
        <section>
          <nav>
            <button onClick={() => this.handleInterestPeriodAddClick()}>Add</button>
            {this.generateInterestRateChildren()}
          </nav>
          <article className='graph'>
            <RowChart data={calc.getData()} width={this.state.graphWidth} height={this.state.graphHeight} margin={this.state.graphMargin}></RowChart>
          </article>
          <nav>
            <p className="main-controls">
              Investment:
                  <input
                className="form-control"
                type="number"
                name="Investment"
                placeholder="Investment"
                defaultValue={this.state.investment}
                onChange={this.handleInvestmentChange}>
              </input>

              Repayments:
                  <input
                className="form-control"
                id="repaymentInput"
                type="number"
                name="repayments"
                placeholder="Repayments"
                value={this.state.repayments}
                onChange={this.handleRepaymentChange.bind(this)}>
              </input>
              <button onClick={() => this.handleCalculateRepaymentClick(calc)}>Calculate Repayment</button>

              Payments Per Year:
            <input
                className="form-control"
                type="number"
                name="PaymentFrequency"
                placeholder="# Payments"
                value={this.state.PaymentFrequency}
                onChange={(event) => { this.setState({ PaymentFrequency: event.target.value }) }}>
              </input>
            </p>
          </nav>
        </section>
      </div>
    );
  }
}

export default App;